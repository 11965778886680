<template>
  <nav class="mobile-nav" role="nav">
    <div class="nav">
      <transition name="slideIn" key="first">
        <div class="first-nav" v-if="!showOptions">
          <a href="#faq" @click="toggleNav"
            ><div class="nav-row">
              <p class="text-black login">FAQs</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="20"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M23.883 9.573a.344.344 0 0 1 .047.076.918.918 0 0 1 0 .702.355.355 0 0 1-.048.079 1.193 1.193 0 0 0-.06.095c-.024.041-.047.082-.082.118l-8.93 9.09a.884.884 0 0 1-1.263 0 .92.92 0 0 1 0-1.285l7.406-7.539H6.519A.902.902 0 0 1 5.626 10c0-.502.4-.909.893-.909h14.434l-7.406-7.539a.92.92 0 0 1 0-1.285.88.88 0 0 1 1.263 0l8.93 9.09a.532.532 0 0 1 .081.118l.03.051c.01.017.02.032.032.047ZM.893 9.09h2.21c.493 0 .893.407.893.91 0 .5-.4.908-.893.908H.893A.902.902 0 0 1 0 10c0-.502.4-.909.893-.909Z"
                  fill="#000"
                />
              </svg>
            </div>
            <div class="line"></div>
          </a>
          <router-link :to="currentRoute === 'home' ? '/merchant' : '/'">
            <div class="nav-row" @click="toggleNav">
              <p class="text-black login">
                {{ currentRoute === "home" ? "For Merchant" : "For Customer" }}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="20"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M23.883 9.573a.344.344 0 0 1 .047.076.918.918 0 0 1 0 .702.355.355 0 0 1-.048.079 1.193 1.193 0 0 0-.06.095c-.024.041-.047.082-.082.118l-8.93 9.09a.884.884 0 0 1-1.263 0 .92.92 0 0 1 0-1.285l7.406-7.539H6.519A.902.902 0 0 1 5.626 10c0-.502.4-.909.893-.909h14.434l-7.406-7.539a.92.92 0 0 1 0-1.285.88.88 0 0 1 1.263 0l8.93 9.09a.532.532 0 0 1 .081.118l.03.051c.01.017.02.032.032.047ZM.893 9.09h2.21c.493 0 .893.407.893.91 0 .5-.4.908-.893.908H.893A.902.902 0 0 1 0 10c0-.502.4-.909.893-.909Z"
                  fill="#000"
                />
              </svg>
            </div>
            <div class="line"></div>
          </router-link>
          <a href="https://blog.usezilla.com/" target="blank" @click="toggleNav"
            ><div class="nav-row">
              <p class="text-black login">Blog</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="20"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M23.883 9.573a.344.344 0 0 1 .047.076.918.918 0 0 1 0 .702.355.355 0 0 1-.048.079 1.193 1.193 0 0 0-.06.095c-.024.041-.047.082-.082.118l-8.93 9.09a.884.884 0 0 1-1.263 0 .92.92 0 0 1 0-1.285l7.406-7.539H6.519A.902.902 0 0 1 5.626 10c0-.502.4-.909.893-.909h14.434l-7.406-7.539a.92.92 0 0 1 0-1.285.88.88 0 0 1 1.263 0l8.93 9.09a.532.532 0 0 1 .081.118l.03.051c.01.017.02.032.032.047ZM.893 9.09h2.21c.493 0 .893.407.893.91 0 .5-.4.908-.893.908H.893A.902.902 0 0 1 0 10c0-.502.4-.909.893-.909Z"
                  fill="#000"
                />
              </svg>
            </div>
            <div class="line"></div>
          </a>
          <a
            href="https://usezilla.notion.site/Zilla-Merchants-db66b8917e2e4f83a2b72e1d70c82d98"
            target="_blank"
            rel="noopener noreferrer"
            ><div class="nav-row">
              <p class="text-black login">Where to shop</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="20"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M23.883 9.573a.344.344 0 0 1 .047.076.918.918 0 0 1 0 .702.355.355 0 0 1-.048.079 1.193 1.193 0 0 0-.06.095c-.024.041-.047.082-.082.118l-8.93 9.09a.884.884 0 0 1-1.263 0 .92.92 0 0 1 0-1.285l7.406-7.539H6.519A.902.902 0 0 1 5.626 10c0-.502.4-.909.893-.909h14.434l-7.406-7.539a.92.92 0 0 1 0-1.285.88.88 0 0 1 1.263 0l8.93 9.09a.532.532 0 0 1 .081.118l.03.051c.01.017.02.032.032.047ZM.893 9.09h2.21c.493 0 .893.407.893.91 0 .5-.4.908-.893.908H.893A.902.902 0 0 1 0 10c0-.502.4-.909.893-.909Z"
                  fill="#000"
                />
              </svg>
            </div>
            <div class="line"></div>
          </a>
          <a
            href="https://usezilla.com/cardzilla"
            target="_blank"
            rel="noopener noreferrer"
            ><div class="nav-row">
              <p class="text-black login">Cardzilla</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="20"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M23.883 9.573a.344.344 0 0 1 .047.076.918.918 0 0 1 0 .702.355.355 0 0 1-.048.079 1.193 1.193 0 0 0-.06.095c-.024.041-.047.082-.082.118l-8.93 9.09a.884.884 0 0 1-1.263 0 .92.92 0 0 1 0-1.285l7.406-7.539H6.519A.902.902 0 0 1 5.626 10c0-.502.4-.909.893-.909h14.434l-7.406-7.539a.92.92 0 0 1 0-1.285.88.88 0 0 1 1.263 0l8.93 9.09a.532.532 0 0 1 .081.118l.03.051c.01.017.02.032.032.047ZM.893 9.09h2.21c.493 0 .893.407.893.91 0 .5-.4.908-.893.908H.893A.902.902 0 0 1 0 10c0-.502.4-.909.893-.909Z"
                  fill="#000"
                />
              </svg>
            </div>
            <div class="line"></div>
          </a>

          <div class="nav-row" @click="handleOptions('login')">
            <p>Login</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="20"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.883 9.573a.344.344 0 0 1 .047.076.918.918 0 0 1 0 .702.355.355 0 0 1-.048.079 1.193 1.193 0 0 0-.06.095c-.024.041-.047.082-.082.118l-8.93 9.09a.884.884 0 0 1-1.263 0 .92.92 0 0 1 0-1.285l7.406-7.539H6.519A.902.902 0 0 1 5.626 10c0-.502.4-.909.893-.909h14.434l-7.406-7.539a.92.92 0 0 1 0-1.285.88.88 0 0 1 1.263 0l8.93 9.09a.532.532 0 0 1 .081.118l.03.051c.01.017.02.032.032.047ZM.893 9.09h2.21c.493 0 .893.407.893.91 0 .5-.4.908-.893.908H.893A.902.902 0 0 1 0 10c0-.502.4-.909.893-.909Z"
                fill="#000"
              />
            </svg>
          </div>

          <div class="nav-row" @click="handleOptions('create')">
            <p>Create account</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="20"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.883 9.573a.344.344 0 0 1 .047.076.918.918 0 0 1 0 .702.355.355 0 0 1-.048.079 1.193 1.193 0 0 0-.06.095c-.024.041-.047.082-.082.118l-8.93 9.09a.884.884 0 0 1-1.263 0 .92.92 0 0 1 0-1.285l7.406-7.539H6.519A.902.902 0 0 1 5.626 10c0-.502.4-.909.893-.909h14.434l-7.406-7.539a.92.92 0 0 1 0-1.285.88.88 0 0 1 1.263 0l8.93 9.09a.532.532 0 0 1 .081.118l.03.051c.01.017.02.032.032.047ZM.893 9.09h2.21c.493 0 .893.407.893.91 0 .5-.4.908-.893.908H.893A.902.902 0 0 1 0 10c0-.502.4-.909.893-.909Z"
                fill="#000"
              />
            </svg>
          </div>
          <div class="line"></div>

          <a
            href="https://usezilla.notion.site/Developers-Documentation-3472cedcf86d47378dec187b32445eb1"
            target="_blank"
            rel="noopener noreferrer"
            ><div class="nav-row">
              <p class="text-black login">Developers' doc</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="20"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M23.883 9.573a.344.344 0 0 1 .047.076.918.918 0 0 1 0 .702.355.355 0 0 1-.048.079 1.193 1.193 0 0 0-.06.095c-.024.041-.047.082-.082.118l-8.93 9.09a.884.884 0 0 1-1.263 0 .92.92 0 0 1 0-1.285l7.406-7.539H6.519A.902.902 0 0 1 5.626 10c0-.502.4-.909.893-.909h14.434l-7.406-7.539a.92.92 0 0 1 0-1.285.88.88 0 0 1 1.263 0l8.93 9.09a.532.532 0 0 1 .081.118l.03.051c.01.017.02.032.032.047ZM.893 9.09h2.21c.493 0 .893.407.893.91 0 .5-.4.908-.893.908H.893A.902.902 0 0 1 0 10c0-.502.4-.909.893-.909Z"
                  fill="#000"
                />
              </svg>
            </div>
            <div class="line"></div>
          </a>
        </div>
        <div class="second-nav" v-else key="second">
          <div class="back" @click="showOptions = false">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="11"
              fill="none"
            >
              <path
                d="M0 5.617 5.317.072s.285-.207.56.08c.276.288 0 .54 0 .54l-4.73 4.934 4.506 4.7s.235.295-.017.557c-.252.263-.603 0-.603 0L0 5.617Z"
                fill="#9D9D9D"
              />
            </svg>
            <p>Back</p>
          </div>
          <div class="nav-row">
            <a
              :href="
                optionValue === 'login'
                  ? `${customerUrl}/login`
                  : `${customerUrl}/register`
              "
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>
                {{
                  optionValue === "login"
                    ? `Shopper's login`
                    : `Create a Shopper's account`
                }}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="20"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M23.883 9.573a.344.344 0 0 1 .047.076.918.918 0 0 1 0 .702.355.355 0 0 1-.048.079 1.193 1.193 0 0 0-.06.095c-.024.041-.047.082-.082.118l-8.93 9.09a.884.884 0 0 1-1.263 0 .92.92 0 0 1 0-1.285l7.406-7.539H6.519A.902.902 0 0 1 5.626 10c0-.502.4-.909.893-.909h14.434l-7.406-7.539a.92.92 0 0 1 0-1.285.88.88 0 0 1 1.263 0l8.93 9.09a.532.532 0 0 1 .081.118l.03.051c.01.017.02.032.032.047ZM.893 9.09h2.21c.493 0 .893.407.893.91 0 .5-.4.908-.893.908H.893A.902.902 0 0 1 0 10c0-.502.4-.909.893-.909Z"
                  fill="#000"
                />
              </svg>
            </a>
          </div>
          <div class="line"></div>
          <div class="nav-row">
            <a
              :href="
                optionValue === 'login'
                  ? `${merchantUrl}/login`
                  : `${merchantUrl}/register`
              "
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>
                {{
                  optionValue === "login"
                    ? `Merchant login`
                    : `Create a Merchant's account`
                }}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="20"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M23.883 9.573a.344.344 0 0 1 .047.076.918.918 0 0 1 0 .702.355.355 0 0 1-.048.079 1.193 1.193 0 0 0-.06.095c-.024.041-.047.082-.082.118l-8.93 9.09a.884.884 0 0 1-1.263 0 .92.92 0 0 1 0-1.285l7.406-7.539H6.519A.902.902 0 0 1 5.626 10c0-.502.4-.909.893-.909h14.434l-7.406-7.539a.92.92 0 0 1 0-1.285.88.88 0 0 1 1.263 0l8.93 9.09a.532.532 0 0 1 .081.118l.03.051c.01.017.02.032.032.047ZM.893 9.09h2.21c.493 0 .893.407.893.91 0 .5-.4.908-.893.908H.893A.902.902 0 0 1 0 10c0-.502.4-.909.893-.909Z"
                  fill="#000"
                />
              </svg>
            </a>
          </div>
        </div>
      </transition>
    </div>
  </nav>
</template>
<script>
  import { mapActions } from "vuex";
  import Button from "@/UI/Button";
  export default {
    components: {
      Button,
    },
    data: () => ({
      showOptions: false,
      optionValue: "",
      customerUrl: process.env.VUE_APP_CUSTOMER_URL,
      merchantUrl: process.env.VUE_APP_MERCHANT_URL,
    }),
    computed: {
      currentRoute() {
        if (this.$route.path === "/") {
          return "home";
        } else {
          return "merchant";
        }
      },
    },
    methods: {
      ...mapActions("nav", ["toggleNav"]),
      handleOptions(value) {
        this.optionValue = value;
        this.showOptions = true;
      },
      handleNavigation() {
        if (this.optionValue === "login") {
        }
      },
    },
  };
</script>
<style scoped>
  .button {
    width: 16rem;
    margin-top: 3rem;
    text-align: center;
    /* margin: auto; */
  }
  .nav {
    margin: auto;
    margin-top: 5.563rem;
    width: 90%;
    /* text-align: center; */
  }
  .nav__doc {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nav__doc p {
    margin-right: 3rem;
  }
  .mobile-nav {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    top: 0;
    left: 0;
    z-index: 5;
    /* padding: 1rem; */
  }
  .nav-row a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 1.875rem 0; */
    width: 100%;
  }
  .nav-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.875rem 0;
  }
  .nav-row p {
    font-weight: 800;
    font-size: 20px;
  }

  .line {
    width: 100%;
    border: 0.5px solid rgba(0, 0, 0, 0.2);
    /* margin-right: 3rem */
  }
  .back p {
    font-size: 15px;
    color: #9d9d9d;
    font-weight: 600;
    margin-top: 0.2rem;
  }
  .back {
    display: flex;
    align-items: center;
    margin-bottom: 2.563rem;
  }
  .back svg {
    margin-right: 9.8px;
  }
  .slideIn-enter-active,
  .fadeIn-leave-active {
    transition: all 0.3s ease-out;
    transform: translateX(0);
  }
  .slideIn-enter,
  .slideIn-leave-to {
    opacity: 0;

    transform: translateX(10px);
  }
  a {
    text-decoration: none;
    color: initial;
  }
  @media (min-width: 768px) {
    .mobile-nav {
      display: none;
    }
  }
</style>
